/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDZmgQbXK3IHGk8AuLqTZMZUXQnN50xDyw",
  "appId": "1:639437834226:web:3ef0dd2e0f6730c2580d6b",
  "authDomain": "schooldog-i-oconee-ga.firebaseapp.com",
  "measurementId": "G-FCCTWJ1N80",
  "messagingSenderId": "639437834226",
  "project": "schooldog-i-oconee-ga",
  "projectId": "schooldog-i-oconee-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-oconee-ga.appspot.com"
}
